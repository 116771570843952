import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  launch(event) {
    console.log('Facebook event');
    console.log(event.currentTarget);
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${event.currentTarget.dataset.url}`,
      'facebook-share-dialog',
      'width=800,height=600'
    );
  }

}
